"use client";

import { useSelectedLayoutSegments } from "next/navigation";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import { useAppRouter } from "@/hooks/common/useAppRouter";
import { useMediaQuery } from "@/hooks/common/useMediaQuery";

import { SCREEN_SIZES } from "@/common/globalTypes/enums/common";

const SearchBtn = () => {
  const { appPush } = useAppRouter();
  const segments = useSelectedLayoutSegments();
  const isMobile = useMediaQuery(SCREEN_SIZES.sm);
  const isShowSearchBtn = segments[0] !== "search" && isMobile;

  const onClickSearch = () => {
    appPush("/search");
  };

  if (!isShowSearchBtn) {
    return null;
  }

  return (
    <Button
      size="icon"
      variant="ghost"
      className="rounded-full"
      onClick={onClickSearch}
    >
      <MagnifyingGlassIcon style={{ width: "20px", height: "20px" }} />
    </Button>
  );
};

export default SearchBtn;
