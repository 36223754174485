import { useMemo } from "react";
import { notFound } from "next/navigation";
import { useQuery } from "@tanstack/react-query";

import { projectByIdKey } from "@/hooks/apis/queries/useProjectByIdQuery/constants";
import { useAuthUser } from "@/hooks/common/useAuthUser";

import { projectApiHandler } from "@/services/apiHandlers/project";

const useProjectByIdQuery = (checkError: boolean, passedProjectId?: string) => {
  const { isLogged, projectId } = useAuthUser();
  const selectedProjectIdOrPassed = passedProjectId || projectId;

  const enabled = isLogged && Boolean(selectedProjectIdOrPassed);
  const {
    data: projectById,
    isLoading: isLoadingProjectById,
    error,
  } = useQuery({
    queryKey: [projectByIdKey, selectedProjectIdOrPassed],
    queryFn: () => projectApiHandler.getProjectById(selectedProjectIdOrPassed),
    enabled,
  });

  const projectByIdForSwitcher = useMemo(() => {
    if (!projectById) {
      return [];
    }

    return [{ label: projectById.name, value: String(projectById.id) }];
  }, [projectById]);

  if (error && checkError) {
    notFound();
  }

  return {
    projectById,
    projectByIdForSwitcher,
    isLoadingProjectById,
  };
};

export default useProjectByIdQuery;
