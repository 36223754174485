const createQueryParam = (
  queryParams: Record<never, never>,
  action: "create" | "delete",
) => {
  const url = new URL(window.location.href);

  for (const [name, value] of Object.entries(queryParams)) {
    if (action === "create") {
      url.searchParams.set(name, value as string);
    } else {
      url.searchParams.delete(name);
    }
  }

  return `${url.pathname.toString()}?${url.searchParams.toString()}`;
};

export default createQueryParam;
