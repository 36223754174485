import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { useAuthUser } from "@/hooks/common/useAuthUser";

import { projectsKey } from "./constants";

import { pageAndLimitDefaultValue } from "@/common/constants/common";
import { projectApiHandler } from "@/services/apiHandlers/project";
import type { IProjectsRequest } from "@/services/apiHandlers/project/types";

const useProjectsInfiniteQuery = (applicationId?: string) => {
  const { isLogged, projectId } = useAuthUser();
  const [searchTerm, setSearchTerm] = useState("");
  const enabled = (applicationId ? Boolean(applicationId) : true) && isLogged;

  const { data, fetchNextPage, hasNextPage, isLoading, ...infiniteQueryProps } =
    useInfiniteQuery({
      queryKey: [projectsKey, searchTerm, applicationId],
      queryFn: ({ pageParam = 1 }) => {
        const queryParamGetProjects: IProjectsRequest = {
          page: pageParam,
          first: pageAndLimitDefaultValue.first,
          applicationId,
        };

        if (searchTerm) {
          queryParamGetProjects.search = searchTerm;
        }

        return projectApiHandler.getProjects(queryParamGetProjects);
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        const {
          paginatorInfo: { hasMorePages, currentPage },
        } = lastPage;

        return hasMorePages ? currentPage + 1 : undefined;
      },
      enabled,
    });

  const projects = useMemo(
    () => data?.pages.flatMap((project) => project.data) || [],
    [data?.pages],
  );

  const projectsForSwitcher = useMemo(() => {
    const filteredBySelectedProjectId = projectId
      ? projects.filter(({ id }) => id !== +projectId)
      : projects;

    return filteredBySelectedProjectId.map(({ name: label, id: value }) => ({
      label,
      value: String(value),
    }));
  }, [projectId, projects]);

  const onSearchTermHandler = (typesWord: string) => {
    setSearchTerm(typesWord);
  };

  return {
    fetchNextPageProjects: fetchNextPage,
    hasNextPageProject: hasNextPage,
    isLoadingProjectsInfiniteQuery: isLoading,
    projects,
    projectsForSwitcher,
    onSearchTermHandler,
    searchTerm,
    ...infiniteQueryProps,
  };
};

export default useProjectsInfiniteQuery;
