import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

const TanStackQueryClientConfigInstance = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
        gcTime: 15 * 60 * 1000,
        refetchOnWindowFocus: false,
        retry: 2,
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
        refetchOnReconnect: true,
        refetchOnMount: false,
      },
      mutations: {
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error: { message?: string; hideErrorMessage?: boolean }) => {
        if (error?.message && !error?.hideErrorMessage) {
          toast.error(error?.message);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: { message?: string; hideErrorMessage?: boolean }) => {
        if (error?.message && !error?.hideErrorMessage) {
          toast.error(error?.message);
        }
      },
    }),
  });

export default TanStackQueryClientConfigInstance;
