import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/node_modules/next/font/local/target.css?{\"path\":\"src/common/constants/common.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/Inter-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Inter-Medium.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/Inter-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"}],\"preload\":true,\"adjustFontFallback\":\"Arial\",\"display\":\"swap\"}],\"variableName\":\"localInterFont\"}");
;
import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/node_modules/@smastrom/react-rating/dist/style.css");
;
import(/* webpackMode: "eager" */ "/home/ucraft/ucraft-next/src/app/[workspace]/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/atoms/AppLink/appLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/molecules/ProjectSwitcher/projectSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/molecules/SearchBtn/searchBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/molecules/ToggleDarkAndLightMode/toggleDarkAndLightMode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/organisms/Header/addUcraftLogoSinglePage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/organisms/Header/projectSwitcherWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/organisms/Header/ucraftLogoWrapperChangedBackBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/components/organisms/Header/userInfoState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/authProvider/authProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/authProvider/context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/tanStackQueryConfigProvider/tanStackQueryConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/themeProvider/themeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/translationsProvider/context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ucraft/ucraft-next/src/providers/translationsProvider/translationsProvider.tsx");
