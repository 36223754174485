"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/atoms/Avatar";
import { Button } from "@/components/atoms/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/atoms/DropDown";
import { Paragraph } from "@/components/atoms/Paragraph";
import type { ILoggedUserInfoProps } from "@/components/molecules/LoggedUserInfo/types";
import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useTranslations } from "@/hooks/common/useTranslations";

const LoggedUserInfo = ({ user }: ILoggedUserInfoProps) => {
  const { t } = useTranslations("common");
  const { logout, redirectHandlerWithSso } = useAuthUser();
  const fullName = user?.fullName || "";
  const avatarUrl = user?.avatarUrl || undefined;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-10 w-10 rounded-full">
          <Avatar className="h-9 w-9">
            <AvatarImage src={avatarUrl} alt="@shadcn" />
            <AvatarFallback>{fullName.charAt(0)}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel>
          <Paragraph size="small" fontWeight="bold">
            {fullName}
          </Paragraph>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => redirectHandlerWithSso("dashboard", "/")}
          >
            <Paragraph size="small" fontWeight="regular">
              {t("myProjects")}
            </Paragraph>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => redirectHandlerWithSso("blank", "/")}
          >
            <Paragraph size="small" fontWeight="regular">
              {t("myBrandbooks")}
            </Paragraph>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => redirectHandlerWithSso("accounts", "/")}
          >
            <Paragraph size="small" fontWeight="regular">
              {t("accountSettings")}
            </Paragraph>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="text-destructive focus:text-destructive"
          onClick={logout}
        >
          <Paragraph size="small" fontWeight="regular">
            {t("logOut")}
          </Paragraph>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

LoggedUserInfo.displayName = "LoggedUserInfo";

export default LoggedUserInfo;
