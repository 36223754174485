import { useCallback, useEffect, useRef, useState } from "react";

const useScrollNavigation = () => {
  const [isVisible, setNavVisible] = useState(true);
  const lastScrollTop = useRef(0);
  const ticking = useRef(false);

  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop.current) {
          setNavVisible(false); // Scrolling down
        } else {
          setNavVisible(true); // Scrolling up
        }

        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return { isVisible };
};

export default useScrollNavigation;
