"use client";

import { PersonIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms/DropDown";
import { Paragraph } from "@/components/atoms/Paragraph";
import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useTranslations } from "@/hooks/common/useTranslations";

const LoginOrSignUp = () => {
  const { t } = useTranslations("common");
  const { logIn, signUpHandler } = useAuthUser();

  return (
    <div className="flex items-center gap-2">
      <div className="hidden max-sm:block">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="relative h-10 w-10 rounded-full"
            >
              <PersonIcon />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" forceMount>
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={() => logIn()}>
                <Paragraph size="small" fontWeight="regular">
                  {t("logIn")}
                </Paragraph>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => signUpHandler()}>
                <Paragraph size="small" fontWeight="regular">
                  {t("signUp")}
                </Paragraph>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="flex items-center gap-2 max-sm:hidden">
        <Button variant="outline" onClick={() => logIn()}>
          <Paragraph size="small" fontWeight="regular">
            {t("logIn")}
          </Paragraph>
        </Button>
        <Button onClick={() => signUpHandler()}>
          <Paragraph size="small" fontWeight="regular">
            {t("signUp")}
          </Paragraph>
        </Button>
      </div>
    </div>
  );
};

LoginOrSignUp.displayName = "LoginOrSignUp";

export default LoginOrSignUp;
