"use client";

import type { PropsWithChildren } from "react";
import { useSelectedLayoutSegments } from "next/navigation";

const ProjectSwitcherWrapper = ({ children }: PropsWithChildren) => {
  const segements = useSelectedLayoutSegments();
  const isShowProjectSwitcher =
    segements.length <= 2 && segements?.[1] !== "projects";

  if (!isShowProjectSwitcher) {
    return null;
  }

  return children;
};

export default ProjectSwitcherWrapper;
