import type { PureNullable } from "@/common/globalTypes/utillityTypes/common";
import { BaseApiHandler } from "@/services/apiHandlers/baseApiHandler";
import type {
  IProjectResponse,
  IProjectsRequest,
  IProjectsResponse,
} from "@/services/apiHandlers/project/types";

class ProjectApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  async getProjectById(projectId?: PureNullable<string>) {
    if (projectId) {
      return this.request.accountsFetcher.get<IProjectResponse>({
        url: `api/projects/${projectId}`,
        cache: "no-cache",
        hideErrorMessage: true,
      });
    }

    return null;
  }

  async getProjects(queryParam: IProjectsRequest) {
    const { applicationId, ...pageAndFirst } = queryParam;

    return this.request.accountsFetcher.get<IProjectsResponse>({
      url: "api/projects",
      cache: "no-cache",
      queryParam: {
        ...pageAndFirst,
        ...(applicationId ? { applicationId } : {}),
      },
    });
  }
}

const projectApiHandler = new ProjectApiHandler();
export default projectApiHandler;
