"use client";

import type { PropsWithChildren } from "react";
import { useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import TanStackQueryClientConfigInstance from "@/providers/tanStackQueryConfigProvider/constants";

const TanStackQueryConfigProvider = ({ children }: PropsWithChildren) => {
  const [queryClient] = useState(TanStackQueryClientConfigInstance);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    </QueryClientProvider>
  );
};

export default TanStackQueryConfigProvider;
