import { Fragment } from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import { Paragraph } from "@/components/atoms/Paragraph";
import type { IProjectSwitcherProps } from "@/components/molecules/ProjectSwitcher/types";
import { useMediaQuery } from "@/hooks/common/useMediaQuery";
import { useTranslations } from "@/hooks/common/useTranslations";

import { SCREEN_SIZES } from "@/common/globalTypes/enums/common";

const ProjectSwitcherTrigger = ({ label, ...props }: IProjectSwitcherProps) => {
  const { t } = useTranslations("common");
  const isMobile = useMediaQuery(SCREEN_SIZES.sm);
  const paragraphType = label ? null : "secondary";
  const electedProjectName = label || t("selectProject");

  const Content = (
    <>
      <Paragraph
        size="base"
        fontWeight="regular"
        type={paragraphType}
        className={`line-clamp-1  ${isMobile ? "pl-4" : ""}`}
      >
        {electedProjectName}
      </Paragraph>
      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
    </>
  );

  return (
    <Fragment key="projectSwitcher">
      {isMobile ? (
        <div
          className="flex items-center justify-between cursor-pointer px-4 py-4"
          {...props}
        >
          {Content}
        </div>
      ) : (
        <Button
          variant="outline"
          role="combobox"
          className="w-[200px] justify-between"
          {...props}
        >
          {Content}
        </Button>
      )}
    </Fragment>
  );
};

export default ProjectSwitcherTrigger;
