"use client";

import { useCallback, useEffect, useRef } from "react";

import type { InfiniteScrollProps } from "@/components/atoms/InfiniteScrollComponent/types";

const InfiniteScroll = ({
  hasMore,
  children,
  loadMore,
  target,
  loader,
}: InfiniteScrollProps) => {
  const observerElement = useRef<HTMLDivElement | null>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          loadMore();
        }
      });
    },
    [hasMore, loadMore],
  );

  const setupIntersectionObserver = useCallback((): IntersectionObserver => {
    const targetElement = target ? document.getElementById(target) : null;

    const observer = new IntersectionObserver(
      (entries) => {
        handleIntersection(entries);
      },
      {
        root: targetElement || null,
        rootMargin: "100px",
        threshold: 0,
      },
    );

    if (observerElement.current) {
      observer.observe(observerElement.current);
    }

    return observer;
  }, [handleIntersection, target]);

  const cleanupObserver = (observer: IntersectionObserver): void => {
    observer.disconnect();
  };

  useEffect(() => {
    const observer = setupIntersectionObserver();

    return () => {
      cleanupObserver(observer);
    };
  }, [hasMore, loadMore, setupIntersectionObserver, target]);

  return (
    <div>
      {children}

      {hasMore && (
        <div ref={observerElement} id="observer">
          {loader && <div className="mt-4">{loader}</div>}
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;
