import type { ElementRef } from "react";
import * as React from "react";
import { forwardRef } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Command as CommandPrimitive } from "cmdk";
import { Loader2 } from "lucide-react";

import type { ICommandInputProps } from "@/components/atoms/Command/types";
import cn from "@/helpers/cn";

const CommandInput = forwardRef<
  ElementRef<typeof CommandPrimitive.Input>,
  ICommandInputProps
>(({ className, isLoading = false, ...props }, ref) => (
  <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
    {isLoading ? (
      <Loader2 className="animate-spin mr-2 h-4 w-4 shrink-0 opacity-50" />
    ) : (
      <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
    )}

    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        "flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

export default CommandInput;
