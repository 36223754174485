export const themes = [
  {
    label: "light",
    value: "light",
  },
  {
    label: "dark",
    value: "dark",
  },
  {
    label: "system",
    value: "system",
  },
];
