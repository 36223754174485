export { default as Dialog } from "./dialog";
export { default as DialogClose } from "./dialogClose";
export { default as DialogContent } from "./dialogContent";
export { default as DialogDescription } from "./dialogDescription";
export { default as DialogFooter } from "./dialogFooter";
export { default as DialogHeader } from "./dialogHeader";
export { default as DialogOveral } from "./dialogOveral";
export { default as DialogPortal } from "./dialogPortal";
export { default as DialogTitle } from "./dialogTitle";
export { default as DialogTrigger } from "./dialogTrigger";
