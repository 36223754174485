"use client";

import type { PropsWithChildren } from "react";
import { Fragment } from "react";
import { useSelectedLayoutSegments } from "next/navigation";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import { useAppRouter } from "@/hooks/common/useAppRouter";

const UcraftLogoWrapperChangedBackBtn = ({ children }: PropsWithChildren) => {
  const segments = useSelectedLayoutSegments();
  const { back } = useAppRouter();
  const isAppSinglePage =
    segments.length === 1 &&
    segments[0] !== "search" &&
    segments[0] !== "privacy-policy" &&
    segments[0] !== "terms-and-conditions";

  return (
    <Fragment key="UcraftLogoWrapper">
      {isAppSinglePage ? (
        <Button
          size="icon"
          variant="ghost"
          className="rounded-full"
          onClick={back}
        >
          <ChevronLeftIcon style={{ width: "20px", height: "20px" }} />
        </Button>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default UcraftLogoWrapperChangedBackBtn;
