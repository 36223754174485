"use client";

import { Fragment } from "react";

import { LoggedUserInfo } from "@/components/molecules/LoggedUserInfo";
import LoginOrSignUp from "@/components/molecules/LoginOrSignUp/loginOrSignUp";
import { useAuthUser } from "@/hooks/common/useAuthUser";

const UserInfoState = () => {
  const { isLogged, user } = useAuthUser();

  return (
    <Fragment key="userState">
      {isLogged ? <LoggedUserInfo user={user} /> : <LoginOrSignUp />}
    </Fragment>
  );
};

export default UserInfoState;
