"use client";

import type { PropsWithChildren } from "react";
import { useSelectedLayoutSegments } from "next/navigation";

const AddUcraftLogoSinglePage = ({ children }: PropsWithChildren) => {
  const segements = useSelectedLayoutSegments();
  const isAppSinglePage =
    segements.length === 1 &&
    segements[0] !== "search" &&
    segements[0] !== "privacy-policy" &&
    segements[0] !== "terms-and-conditions";

  if (!isAppSinglePage) {
    return null;
  }

  return children;
};

export default AddUcraftLogoSinglePage;
