import type { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";

import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useTranslations } from "@/hooks/common/useTranslations";

import { APP_STORE_SUPPORTED_LANGUAGES_ENUM } from "@/common/globalTypes/enums/common";

const useAppRouter = () => {
  const { push, replace, ...routerProperties } = useRouter();
  const { currentLocale } = useTranslations();
  const { isCustomDomain, workspaceConfig } = useAuthUser();

  const rewriteUrl = (href: string) => {
    const isDefaultLang =
      currentLocale === APP_STORE_SUPPORTED_LANGUAGES_ENUM.en;

    if (isCustomDomain) {
      return isDefaultLang ? href : `/${currentLocale}${href}`;
    }

    return isDefaultLang
      ? `/${workspaceConfig?.workspace}/${href}`
      : `/${workspaceConfig?.workspace}/${currentLocale}${href}`;
  };

  const appPush = (href: string, options?: NavigateOptions) => {
    return push(rewriteUrl(href), {
      ...options,
    });
  };

  const appReplace = (href: string, options?: NavigateOptions) => {
    return replace(href, {
      ...options,
    });
  };

  return {
    appPush,
    appReplace,
    ...routerProperties,
  };
};

export default useAppRouter;
