import type { ComponentPropsWithoutRef, ElementRef } from "react";
import * as React from "react";
import { forwardRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import cn from "@/helpers/cn";

const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));

DialogTitle.displayName = DialogPrimitive.Title.displayName;

export default DialogTitle;
