"use client";

import { useLayoutEffect } from "react";
import { useSearchParams } from "next/navigation";
import { CheckIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/atoms/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/atoms/Command";
import { InfiniteScrollComponent } from "@/components/atoms/InfiniteScrollComponent";
import { Paragraph } from "@/components/atoms/Paragraph";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import { ScrollArea } from "@/components/atoms/ScrollArea";
import { Skeleton } from "@/components/atoms/Skeleton";
import { projectSwitcherId } from "@/components/molecules/ProjectSwitcher/constants";
import ProjectSwitcherTrigger from "@/components/molecules/ProjectSwitcher/projectSwitcherTrigger";
import cn from "@/helpers/cn";
import createQueryParam from "@/helpers/createQueryParam";
import { useProjectByIdQuery } from "@/hooks/apis/queries/useProjectByIdQuery";
import { useProjectsInfiniteQuery } from "@/hooks/apis/queries/useProjectsInfiniteQuery";
import { useAppRouter } from "@/hooks/common/useAppRouter";
import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useMediaQuery } from "@/hooks/common/useMediaQuery";
import { useScrollNavigation } from "@/hooks/common/useScrollNavigation";
import { useTranslations } from "@/hooks/common/useTranslations";

import { deleteCookieServerAction, setCookieServerAction } from "@/app/actions";
import { queryParamRedirectedFromDashboard } from "@/common/constants/common";
import { SCREEN_SIZES } from "@/common/globalTypes/enums/common";

const ProjectSwitcher = () => {
  const { t } = useTranslations("common");
  const { redirectHandlerWithSso } = useAuthUser();
  const searchParams = useSearchParams();
  const { appReplace } = useAppRouter();
  const { isVisible: isVisibleProjectSwitcher } = useScrollNavigation();
  const isMobile = useMediaQuery(SCREEN_SIZES.sm);
  const { projectByIdForSwitcher, projectById, isLoadingProjectById } =
    useProjectByIdQuery(false);

  const {
    projectsForSwitcher,
    fetchNextPageProjects,
    hasNextPageProject,
    isLoadingProjectsInfiniteQuery,
    onSearchTermHandler,
    searchTerm,
  } = useProjectsInfiniteQuery();

  useLayoutEffect(() => {
    if (searchParams.get(queryParamRedirectedFromDashboard)) {
      const deletedProjectIdQueryParam = createQueryParam(
        {
          projectId: null,
        },
        "delete",
      );

      appReplace(deletedProjectIdQueryParam, { scroll: false });
    }
  }, [appReplace, searchParams]);

  const projectSwitcherMergedWithSelectedProject =
    projectByIdForSwitcher.concat(projectsForSwitcher);

  const isLoadingProjectSwitcher =
    (isLoadingProjectById || isLoadingProjectsInfiniteQuery) &&
    !searchTerm.length;

  const onChangeProject = async (currentProjectId: string) => {
    if (currentProjectId !== String(projectById?.id)) {
      await setCookieServerAction(
        queryParamRedirectedFromDashboard,
        currentProjectId,
      );
    } else {
      await deleteCookieServerAction(queryParamRedirectedFromDashboard);
    }

    window.location.reload();
  };

  const createNewProjectHandler = () => {
    return redirectHandlerWithSso("onboarding-start", "/");
  };

  if (isVisibleProjectSwitcher && isMobile) {
    return null;
  }

  return (
    <Popover>
      {isLoadingProjectSwitcher ? (
        <Skeleton className="w-[200px] h-8 max-sm:w-full max-sm:h-12" />
      ) : (
        <PopoverTrigger asChild>
          <ProjectSwitcherTrigger label={projectById?.name} />
        </PopoverTrigger>
      )}

      <PopoverContent
        className={`p-0 ${isMobile ? "popover-full-width" : "w-[200px]"}`}
      >
        <Command>
          {projectSwitcherMergedWithSelectedProject.length ? (
            <CommandInput
              placeholder={t("searchProject")}
              className="h-9 max-sm:h-12"
              isLoading={isLoadingProjectsInfiniteQuery}
              onValueChange={onSearchTermHandler}
              value={searchTerm}
            />
          ) : (
            <CommandEmpty className="p-0">
              <div className="flex flex-col gap-6 p-4">
                <div className="flex flex-col gap-2 items-center justify-center">
                  <Paragraph size="base">{t("createProject")}</Paragraph>
                  <Paragraph
                    size="small"
                    type="secondary"
                    className="text-center"
                  >
                    {t("startNewProject")}
                  </Paragraph>
                </div>
                <div>
                  <Button className="w-full" onClick={createNewProjectHandler}>
                    {t("createProject")}
                  </Button>
                </div>
              </div>
            </CommandEmpty>
          )}

          <CommandList asChild>
            <ScrollArea className="max-h-80" id={projectSwitcherId}>
              <InfiniteScrollComponent
                loader={<span />}
                loadMore={fetchNextPageProjects}
                hasMore={hasNextPageProject}
                target={projectSwitcherId}
              >
                <CommandGroup forceMount>
                  {projectSwitcherMergedWithSelectedProject.map(
                    ({ label: projectName, value: projectValue }) => (
                      <CommandItem
                        key={projectValue}
                        value={projectValue}
                        onSelect={(currentValue) =>
                          onChangeProject(currentValue)
                        }
                        className="cursor-pointer"
                        asChild
                      >
                        <Paragraph size="base" fontWeight="regular">
                          {projectName}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              projectValue === String(projectById?.id)
                                ? "opacity-100"
                                : "opacity-0",
                            )}
                          />
                        </Paragraph>
                      </CommandItem>
                    ),
                  )}
                </CommandGroup>
              </InfiniteScrollComponent>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

ProjectSwitcher.displayName = "ProjectSwitcher";

export default ProjectSwitcher;
